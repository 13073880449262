import React from "react";
import { Link, useScrollRestoration } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import type { WindowLocation } from "@reach/router";
import useAnimationContext from "../../contexts/animation-context";
import { navigate } from "@reach/router";
import { getExitVariant, AnimationDirection } from "../../utilities/animation";
import { useLocation } from "@reach/router";
import cx from "classnames";

interface BlogListItemProps {
  post: any;
  visualSpec?: "default" | "mini";
  shouldRestoreScroll?: boolean;
}
export const BlogListItem = ({
  post: {
    frontmatter: { title, featuredImage, date, description },
    excerpt,
    fields: { slug },
  },
  visualSpec,
  shouldRestoreScroll,
}: BlogListItemProps) => {
  const heroImage = getImage(featuredImage);
  const [month, day, year] = date.split(" ");

  const { blogsPageAnimationControls, homePageAnimationControls } =
    useAnimationContext();

  const { RTL, BTU } = AnimationDirection;
  const { pathname } = useLocation();

  return (
    <Link
      to={`/blogs${slug}`}
      itemProp="url"
      key={slug}
      className={cx(styles.blogListLink, {
        [styles.mini]: visualSpec === "mini",
      })}
      state={{ initialAnimateDir: pathname === "/" ? BTU : RTL }}
      onClick={async (event): Promise<void> => {
        event.preventDefault();

        if (
          visualSpec === "mini" &&
          typeof (window as any).gtag === "function"
        ) {
          (window as any).gtag("event", "click", {
            target: "prev-article-reminder",
          });
        }

        if (pathname === "/") {
          const exitVariant = getExitVariant(BTU);
          if (exitVariant) {
            await homePageAnimationControls.start(exitVariant);
          }
        } else {
          const exitVariant = getExitVariant(RTL);
          if (exitVariant) {
            await blogsPageAnimationControls.start(exitVariant);
          }
        }
        navigate(`/blogs${slug}`, {
          state: {
            initialAnimateDir: pathname === "/" ? BTU : RTL,
            shouldRestoreScroll,
          },
        });
      }}
    >
      <li className={styles.blogListItem}>
        <div className={styles.backgroundImageContainer}>
          {heroImage && (
            <GatsbyImage
              className={styles.backgroundImage}
              image={heroImage}
              alt="hero image"
            />
          )}
        </div>
        <article
          className={styles.contentContainer}
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className={styles.date}>
            <span className={styles.month}>{month}</span>
            <span className={styles.day}>{day}</span>
            <span className={styles.year}>{year}</span>
          </div>
          <div className={styles.content}>
            <h2 className={styles.title} itemProp="headline">
              {title || slug}
            </h2>
            <hr className={styles.divider} />
            <p itemProp="description" className={styles.description}>
              {description || excerpt}
            </p>
          </div>
        </article>
      </li>
    </Link>
  );
};

interface BlogListProps {
  scrollRestorationKey: string;
  posts: any[];
  location: WindowLocation;
}
const BlogList = ({ posts, scrollRestorationKey, location }: BlogListProps) => {
  const latestBlogsListScrollRestoration =
    useScrollRestoration(scrollRestorationKey);

  if (posts.length === 0) {
    return (
      <p>
        No blog posts found. Add markdown posts to "content/blog" (or the
        directory you specified for the "gatsby-source-filesystem" plugin in
        gatsby-config.js).
      </p>
    );
  }

  return (
    <div
      className={styles.blogListContainer}
      {
        // Hack: to make the ref typing work
        ...(latestBlogsListScrollRestoration as any)
      }
    >
      <ol className={styles.blogList}>
        {posts.map((post: any) => (
          <BlogListItem key={post.fields.slug} post={post} />
        ))}
      </ol>
    </div>
  );
};

export default BlogList;
