import React from "react";
import { graphql } from "gatsby";
import type { PageProps } from "gatsby";
import Layout from "../layouts";
import * as styles from "./blogs.module.scss";
import BlogList from "../components/blog-list";
import { motion } from "framer-motion";
import useAnimationContext from "../contexts/animation-context";
import { getInitialVariant } from "../utilities/animation";

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allMarkdownRemark: {
    nodes: {
      excerpt: string;
      fields: {
        slug: string;
      };
      frontmatter: {
        date: string;
        title: string;
        description: string;
        featuredImage: any;
      };
    }[];
  };
};

const LatestBlogs = ({ data, location }: PageProps<DataProps>) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  const { state, pathname } = location;
  let initialAnimateDir;
  if ((state as any)?.initialAnimateDir) {
    initialAnimateDir = (state as any).initialAnimateDir;
  }

  const { variants, blogsPageAnimationControls } = useAnimationContext();

  React.useEffect((): void => {
    blogsPageAnimationControls.start("visible");
  }, []);

  return (
    <motion.div
      key={pathname}
      animate={blogsPageAnimationControls}
      initial={getInitialVariant(initialAnimateDir)}
      variants={variants}
    >
      <Layout
        location={location}
        title={siteTitle}
        seoInfo={{ title: "Blog" }}
        mainClassName={styles.main}
      >
        <div className={styles.container}>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <h1 className={styles.title}>Latest Blog Posts</h1>
              <p className={styles.description}>
                I like to write about new technical things that I’ve learned. My
                hope is that some of these will be useful to others and help
                them learn as well. While I’ll generally write about
                technologies I’ve used or am learning, I’ll also sometimes post
                about projects I’ve built as well.
              </p>
            </div>
          </div>
          <BlogList
            scrollRestorationKey="latest-blogs-list"
            posts={data.allMarkdownRemark.nodes}
            location={location}
          />
        </div>
      </Layout>
    </motion.div>
  );
};

export default LatestBlogs;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM DD YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
  }
`;
